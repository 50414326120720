import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as firebase from 'firebase/app';

import { tap, take, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../auth.service';
import { Location } from '@angular/common';

@Injectable()
export class AuthAdminGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router, private _location: Location) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.auth.user.pipe(
      map(user => (user != null) && user.blocked != true && user.admin),
      tap(isValid => {
        if (!isValid) {
          console.log('admin access denied')
        }
      })
    )
  }
}