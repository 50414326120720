import { Component, OnInit, Input } from '@angular/core';
import { Mission } from 'src/app/models/mission';

@Component({
  selector: '[mission-cell]',
  templateUrl: './mission-cell.component.html',
  styleUrls: ['./mission-cell.component.css']
})
export class MissionCellComponent implements OnInit {

  @Input()
  public mission: Mission;

  activeTab:string = "";
  collapsed = false;

  constructor() { 
    
  }

  ngOnInit() {
   
  }

  tabChange(title:string){
    if(this.activeTab != title){
      this.activeTab = title;
    }
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed
  }

}
