import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { switchMap, map, take, first } from 'rxjs/operators';
import { Observable, from } from "rxjs";
import { FirebaseObject } from "../models/global";
import { Ship, OwnedShip } from "../models/ship";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(public afs: AngularFirestore) {

    }

    // GLOBAL

    

    createObject(collection: string, data: any) {
        return this.afs.collection(collection).add(data)
    }

    deleteObject<M extends typeof FirebaseObject>(uid: string, model: M) {
        return from(this.afs.collection(model.collection).doc(uid).delete()).pipe(
            map(_ => true)
        )
    }

    saveObject<M extends typeof FirebaseObject>(data: any, model: M, uid?: string): Observable<boolean> {
        let id = uid == null ? this.afs.createId() : uid
        let ref = this.afs.collection<M>(`${model.collection}`).doc(id)
        return from(ref.set({...data}, { merge: true })).pipe(
            map(_ => true)
        )
    }

    fetchObject<M extends typeof FirebaseObject>(uid: string, model: M): Observable<any> {
        return this.objectRef(uid, model)
            .valueChanges().pipe(map(a => {
                return new model().deserialize(a, uid) as M
            }))
    }

    objectRef<M extends typeof FirebaseObject>(uid: string, model: M) {
        return this.afs.collection(model.collection).doc<M>(uid)
    }

    public objectsWithParams<M extends typeof FirebaseObject>(model: M, params?: [{ property: string, value: any }], limit?: number, order?: [{ property: string, asc: boolean }]): Observable<any[]> {
        return this.afs.collection<M>(model.collection, ref => {
            let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
            if (params) {
                for (let param of params) {
                    query = query.where(param.property, '==', param.value)
                }
            }
            if (order) {
                for (let od of order) {
                    query = query.orderBy(od.property, od.asc ? "asc" : "desc")
                }
            }
            if (limit) {
                query = query.limit(limit)
            }
            return query;
        }).snapshotChanges()
            .pipe(
                map(actions => {
                    return actions.map(a => {
                        const data = a.payload.doc.data() as M;
                        const id = a.payload.doc.id;
                        return new model().deserialize(data, id) as M
                    })
                })
            )
    }


    /*public getAgencyRef(uid: string): AngularFirestoreDocument<Agency> {
        return this.afs.collection("agencies").doc<Agency>(uid)
    }

    public getAgency(uid: string): Observable<Agency> {
        if (uid == null) {
            return null
        }
        return this.getAgencyRef(uid).valueChanges().pipe(map(a => new Agency().deserialize(a, uid)))
    }*/

    // SHIPS

    addShip(ship: Ship, userID: string): Observable<boolean> {
        let own = new OwnedShip()
        own.userID = userID
        own.shipID = ship.uid
        own.shipRef = ship.name
        own.createdAt = new Date()
        return this.saveObject(own, OwnedShip)
    }

}
