import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/user';
import { DataService } from 'src/app/lib/data.service';
import { switchMap, map, take, first } from 'rxjs/operators';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  @Input()
  public title: string;

  @Input()
  public manager = false;

  @Input()
  public query = "default";

  allUsers: User[]
  filteredUsers: User[] = []
  filter = ""

  constructor(private ds: DataService) { 
    
  }

  ngOnInit() {
    let params : [{ property: string, value: any}]
    let order : [{ property: string, asc: boolean}] 
    let limit = null

    if(this.query == "last") {
      limit = 10
      order = [{ property: "createdAt", asc: false }]
    }
    if(this.query == "member") {
      params = [{ property: "status", value: "member"}]
      order = [{ property: "createdAt", asc: false }]
    }
    if(this.query == "applicant") {
      params = [{ property: "status", value: "applicant"}]
      order = [{ property: "createdAt", asc: false }]
    }
    if(this.query == "blocked") {
      params = [{ property: "blocked", value: true}]
      order = [{ property: "createdAt", asc: false }]
    }
    this.ds.objectsWithParams(User, params, limit, order).subscribe(users => {
      this.allUsers = users
      this.filteredUsers = users
    })
  }

  filterChanged(str: string) {
    if(str.length > 0) {
      this.filteredUsers = []
      for(let u of this.allUsers) {
        if(u.nickname.toLowerCase().startsWith(str.toLowerCase())) {
          this.filteredUsers.push(u)
        }
      }
    }
    else {
      this.filteredUsers = this.allUsers
    }
  }

}
