import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Router, Data } from '@angular/router';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: Observable<User>;
  userID: BehaviorSubject<string>;
  userData: User;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private dataService: DataService
  ) {
    this.userID = new BehaviorSubject(null);
    this.user = this.afAuth.authState.pipe(
      switchMap(usr => {
        if (usr) {
          return this.dataService.fetchObject(usr.uid, User).pipe(
            map(u => {
              if (u == null) {
                return of(null)
              }
              //this.router.navigate(["manager"]);
              return u
            }
            )
          )
        } else {
          return of(null)
        }
      })
    )

    this.user.subscribe(usr => {
      if (usr != null) {
        this.userData = usr
        this.userID.next(usr.uid)
        if(usr.authCode == null) {
          let rand = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            this.dataService.saveObject({ authCode: `PRD-${rand.toUpperCase()}`}, User, usr.uid)
        }
        if(usr.createdAt == null) {
            this.dataService.saveObject({ createdAt: new Date()}, User, usr.uid)
        }
      }
      else {
        this.userID.next(null)
      }
    })
  }

  proceedFacebookLogin() {
    let provider = new firebase.auth.FacebookAuthProvider()
    provider.addScope('email');
    this.firebaseSignIn(provider)
  }

  proceedGoogleLogin() {
    let provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    this.firebaseSignIn(provider)
  }

  firebaseSignIn(provider) {
    this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        //this.userData = new User().deserialize(res.user, res.user.uid)
        //this.updateUserData(res.user)
      })
      .catch(err => {
        console.log(err)
        if (err.code == "auth/account-exists-with-different-credential") {
          alert("Il existe déjà un compte associé à cette adresse email utilisant un autre fournisseur. Merci de vous connecter avec ce fournisseur (Facebook ou Google)")
        }
      })
  }

  public logout(redirect: string) {
    console.log("proceeding logout")
    this.afAuth.auth.signOut().then(sign => {
      this.router.navigate([redirect]);
    })
  }

    /*proceedFacebookLogin() {
      let provider = new firebase.auth.FacebookAuthProvider()
      provider.addScope('email');
      this.firebaseSignIn(provider)
    }
  
    proceedGoogleLogin() {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.firebaseSignIn(provider)
    }*/

    /*createAccount(email: string, password: string, invite: Manager) {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password).then(res => {
        this.saveManager(res.user.uid, invite)
      })
    }
  
    public saveManager(uid: string, data: Manager) {
      const managerRef: AngularFirestoreDocument<any> = this.afs.doc(`managers/${uid}`);
      managerRef.set({ ...data }, { merge: true })
      const inviteRef: AngularFirestoreDocument<any> = this.afs.doc(`invites/${data.uid}`);
      inviteRef.delete()
    }
  
  
    loginEmailPassword(email: string, password: string) {
      return this.afAuth.auth.signInWithEmailAndPassword(email, password).then(cred => {
        this.userData = new Manager().deserialize(cred.user, cred.user.uid)
      })
    }
  
    
    }*/



  }
