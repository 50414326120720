import { Component, OnInit, Input } from '@angular/core';
import { MissionType, MissionSection } from 'src/app/models/mission';

@Component({
  selector: '[section-badge]',
  templateUrl: './section-badge.component.html',
  styleUrls: ['./section-badge.component.css']
})
export class SectionBadgeComponent implements OnInit {

  @Input()
  public missionSection: MissionSection;

  constructor() { }

  ngOnInit() {
  }

}
