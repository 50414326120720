import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/user';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/lib/auth.service';
import { DataService } from 'src/app/lib/data.service';

@Component({
  selector: 'app-user-cell',
  templateUrl: './user-cell.component.html',
  styleUrls: ['./user-cell.component.css']
})
export class UserCellComponent implements OnInit {

  @Input()
  public user: User;

  @Input()
  public manager = false;


  constructor(private router: Router, private auth: AuthService, private ds: DataService) { }

  ngOnInit() {
  }

  showFleet() {
    let path = "/manager/org/fleet/" + this.user.uid
    if(this.auth.userID.value == this.user.uid) {
      path = "/manager/user/fleet/me"
    }
    this.router.navigate([path])
  }

  approve() {
    this.setStatus("member")
  }

  revoke() {
    this.setStatus("applicant")
  }

  toggleBlock(val: boolean) {
    this.ds.saveObject({blocked : val}, User, this.user.uid).subscribe()
  }

  setStatus(value: string) {
    this.ds.saveObject({status : value}, User, this.user.uid).subscribe()
  }

}
