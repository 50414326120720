import { Component, OnInit, Input, ViewChild, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-button',
  templateUrl: './dynamic-button.component.html',
  styleUrls: ['./dynamic-button.component.css']
})
export class DynamicButtonComponent implements OnInit {

  @Input()
  public block: boolean = false;
  @Input()
  public title: string = "";
  @Input()
  public class: string = "btn-primary";
  @Input()
  public func: Observable<boolean>;

  @ViewChild('button') button;
  @ViewChild('icon') icon;

  private initialTitle: string;

  loadingClass = "btn-warning"
  successClass = "btn-success"
  errorClass = "btn-danger"

  isLoading = false

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.initialTitle = this.title
    this.renderer.setAttribute(this.button.nativeElement, 'style', this.generateStyle())
    this.updateClass(this.class)
  }

  process() {
    if(this.isLoading) return
    this.loading("Chargement...")
    this.func.subscribe(res => {
      this.delay(1000).then(any => {
        if(res) {
          this.success("Succès !")
        }
        else {
          this.error("Erreur !")
        }
      });
    })
  }

  generateStyle(): string {
    return `btn btn-warning`
  }

  generateClass(className: string): string {
    return `btn ${className} ${this.block ? "btn-block" : ""}`
  }

  updateClass(className: string) {
    this.renderer.setAttribute(this.button.nativeElement, 'class', this.generateClass(className))
  }

  public loading(message: string) {
    this.isLoading = true
    this.title = message
    this.updateClass(this.loadingClass)
    this.renderer.setAttribute(this.icon.nativeElement, 'class', `fa fa-circle-o-notch fa-spin fast-spin`)
    this.renderer.setProperty(this.button.nativeElement, 'disabled', null)
  }

  public success(message: string) {
    this.title = message
    this.updateClass(this.successClass)
    this.renderer.setAttribute(this.icon.nativeElement, 'class', 'fa fa-check')
    this.delay(1000).then(any => {
      this.title = this.initialTitle
      this.renderer.setAttribute(this.icon.nativeElement, 'class', '')
      this.updateClass(this.class)
      this.isLoading = false
    });
  }

  public error(message: string) {
    this.title = message
    this.updateClass(this.errorClass)
    this.renderer.setAttribute(this.icon.nativeElement, 'class', 'fa fa-times')
    this.delay(1000).then(any => {
      this.title = this.initialTitle
      this.renderer.setAttribute(this.icon.nativeElement, 'class', '')
      this.updateClass(this.class)
      this.isLoading = false
    });
  }

  /*public configure(title:string) {
    this.initialTitle = title
    this.title = this.initialTitle
    this.config = config
    this.renderer.setAttribute(this.button.nativeElement, 'style', config.customStyle)
    this.assignClass(this.config.defaultClass)
  }

  public loading(message: string) {
    this.title = message
    this.assignClass(this.config.loadingClass)
    this.renderer.setAttribute(this.icon.nativeElement, 'class', 'fa fa-circle-o-notch fa-spin fast-spin')
    this.renderer.setProperty(this.button.nativeElement, 'disabled', null)
  }

  public success(message: string) {
    this.title = message
    this.assignClass(this.config.successClass)
    this.renderer.setAttribute(this.icon.nativeElement, 'class', 'fa fa-check')
    this.delay(1000).then(any => {
      this.title = this.initialTitle
      this.renderer.setAttribute(this.icon.nativeElement, 'class', '')
      this.assignClass(this.config.defaultClass)
    });
  }

  public error(message: string) {
    this.title = message
    this.assignClass(this.config.errorClass)
    this.renderer.setAttribute(this.icon.nativeElement, 'class', 'fa fa-times')
    this.delay(1000).then(any => {
      this.title = this.initialTitle
      this.renderer.setAttribute(this.icon.nativeElement, 'class', '')
      this.assignClass(this.config.defaultClass)
    });
  }
  
  private assignClass(c: string) {
    this.renderer.setAttribute(this.button.nativeElement, 'class', c)
  }*/

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms));
  }


}
