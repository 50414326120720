export interface Deserializable {
    uid: string;
    deserialize(input: any, uid: string): this
}

export class FirebaseObject {
    static collection: string

    uid: string
    createdAt: any = null

    deserialize(input: any, uid: string): any {
        Object.assign(this, input)
        this.uid = uid
        return this;
    }
}

export class Statistics extends FirebaseObject {
    static collection = "stats"

    orgShipCount = 0
    memberCount = 0
}