import { Component } from '@angular/core';
import { AuthService } from './lib/auth.service';
import { DataService } from './lib/data.service';
import { User } from './models/user';
import { switchMap, map, take, first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})



export class AppComponent {
  title = 'PRISMA DYNAMICS';

  constructor(private auth: AuthService, private ds: DataService, private translate: TranslateService) {

    this.translate.setDefaultLang('fr');
    this.translate.use('fr');


    this.auth.user.pipe(take(1)).subscribe(user => {
      if (user != null) {
        this.ds.saveObject({ lastConnectedAt: new Date() }, User, user.uid)
      }
    })
  }
}






