import { FirebaseObject } from "./global"

export enum UserStatus {
    ghost = "ghost",
    applicant = "applicant",
    member = "member",
}

export class User extends FirebaseObject {

    static collection = "users"

    blocked = false
    email: string = ""
    nickname: string = ""

    status: UserStatus = UserStatus.ghost
    authCode: string = null
    admin: boolean = false

    shipCount: number = 0

    // Timestamps

    rsiData: any
    
    lastConnectedAt: any

    constructor() {
        super()
    }

    fetch(uid: string) {

    }

    deserialize(input: any, uid: string): any {
        super.deserialize(input, uid)

        // some alt deserial

        return this;
    }


}

/*export class UserRsi {

    
    deserialize(input: any): any {
        Object.assign(this, input)
        return this;
    }


}*/