import { FirebaseObject } from "./global";
import { Observable } from "rxjs";

export class Ship extends FirebaseObject {

    static collection = "ships"

    uid: string;
    slug: string;
    name: string;

    cargo: number;

    storeImage: string;
    storeImageSmall: string;

    orgSerial: string;

    backgroundImage: string;
    fleetchartImage: string;
    manufacturer: ShipManufacturer;

    productionStatus?: string;
    productionNote?: string;

    ownings: any;

    deserialize(input: any, uid: string): any {
        super.deserialize(input, uid)
        return this;
    }

    countAll(): number {
        var count = 0
        if (this.ownings != null) {
            for (let ow of Object.keys(this.ownings)) {
                count += this.ownings[ow]
            }
        }
        return count
    }
}

export class OwnedShip extends FirebaseObject {

    static collection = "owned_ships"

    uid: string;
    userID: string;
    shipID: string;
    shipRef: string;
    shipName: string = null;

    deserialize(input: any, uid: string): any {
        super.deserialize(input, uid)
        return this;
    }
}

export interface ShipManufacturer {
    uid: string;
    name: string;
    code: string;
    logo: string;
    slug: string;
}