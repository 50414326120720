import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[mission-mini-cell]',
  templateUrl: './mission-mini-cell.component.html',
  styleUrls: ['./mission-mini-cell.component.css']
})
export class MissionMiniCellComponent implements OnInit {

  public activeTab:string = "";

  constructor() { }

  ngOnInit() {
   
  }

  tabChange(title:string){
    if(this.activeTab != title){
      this.activeTab = title;
    }
  }

}
