import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { tap, take, map } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { UserStatus } from 'src/app/models/user';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    return this.auth.user.pipe(
      map(user => (user != null) && user.blocked != true && user.status == UserStatus.member),
      tap(isValid => {
        console.log("valid", isValid)
        if (!isValid) {
          console.log('standard access denied')
          this.router.navigate(['/']);
        }
      })
    )
  }
}