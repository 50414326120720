import { FirebaseObject } from "./global";

export enum MissionSection {
    trade = "trade",
    tour = "tour",
}

export enum MissionType {
    touring = "touring",
    shuttle = "shuttle",
    cargo = "cargo"
}

export enum MissionPriority {
    normal = "normal",
    urgent = "urgent",
    critical = "critical",
}

export enum MissionStatus {
    pending = "pending",
    accepted = "accepted",
    completed = "completed",
    canceled = "canceled",
}

export class Mission extends FirebaseObject {

    static collection = "missions"
    
    hidden = false
    missionSection: MissionSection
    missionType: MissionType
    missionStatus: MissionStatus = MissionStatus.pending


    createdAt: any = new Date()
    expiresAt: any = new Date()
    priority: MissionPriority = MissionPriority.normal

    maxDurationHours: number

    multiplayers = false

    orgMission = true
    orgPoints = 0
    allowedShips = ""


    description = ""

    deserialize(input: any, uid: string): any {
        super.deserialize(input, uid)

        // some alt deserial

        return this;
    }

    static sample() {
        let mission = new Mission()

        mission.missionType = MissionType.shuttle
        mission.missionSection = MissionSection.tour

        return mission
    }
    
}