import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Ship } from 'src/app/models/ship';
import { DataService } from 'src/app/lib/data.service';
import { switchMap, map, take, first } from 'rxjs/operators';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-fleet-picker',
  templateUrl: './fleet-picker.component.html',
  styleUrls: ['./fleet-picker.component.css']
})
export class FleetPickerComponent implements OnInit {

  @Input()
  public userID: string;

  allShips: (typeof Ship)[]
  filteredShips: (typeof Ship)[] = []
  filter = ""

  constructor(private ds: DataService) {
    ds.objectsWithParams(Ship).pipe(take(1)).subscribe(ships => {
      this.allShips = ships
      this.filteredShips = ships
    })
  }

  ngOnInit() {
    
  }

  filterChanged(str: string) {
    if(str.length > 0) {
      this.filteredShips = []
      for(let ship of this.allShips) {
        if(ship.name.toLowerCase().startsWith(str.toLowerCase())) {
          this.filteredShips.push(ship)
        }
      }
    }
    else {
      this.filteredShips = this.allShips
    }
  }

}
