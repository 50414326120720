import { Component, OnInit, Input } from '@angular/core';
import { OwnedShip, Ship } from 'src/app/models/ship';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/lib/data.service';

@Component({
  selector: 'app-ship-owned',
  templateUrl: './ship-owned.component.html',
  styleUrls: ['./ship-owned.component.css']
})
export class ShipOwnedComponent implements OnInit {

  @Input()
  public ownedShip: OwnedShip;

  @Input()
  public canEdit: boolean = false;

  ship: Observable<(typeof Ship)>


  constructor(private ds: DataService) {

  }

  ngOnInit() {
    this.ship = this.ds.fetchObject(this.ownedShip.shipID, Ship)
  }

  delete() {
    this.ds.deleteObject(this.ownedShip.uid, OwnedShip)
  }

}
