import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { AuthGuard } from './lib/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: "./front/front.module#FrontModule"
  },
  {
    path: 'manager',
    loadChildren: "./manager/manager.module#ManagerModule"
  },
  { path: '**', pathMatch: 'full', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [],
  declarations: [
    NotFoundComponent
  ],
  providers: [
    
  ]
})
export class AppRoutingModule { }