import { Component, OnInit, Input } from '@angular/core';
import { Ship } from 'src/app/models/ship';
import { DataService } from 'src/app/lib/data.service';
import { AuthService } from 'src/app/lib/auth.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-ship-mini',
  templateUrl: './ship-mini.component.html',
  styleUrls: ['./ship-mini.component.css']
})
export class ShipMiniComponent implements OnInit {

  @Input()
  public ship: Ship;
  @Input()
  public userID: string = null;

  constructor(private ds: DataService) { }

  ngOnInit() {

  }

  add(){
    this.ds.addShip(this.ship, this.userID)
  }
}
