import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { map, take, debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/lib/auth.service';
import { DataService } from 'src/app/lib/data.service';

@Component({
  selector: 'app-handle-input',
  templateUrl: './handle-input.component.html',
  styleUrls: ['./handle-input.component.css']
})
export class HandleInputComponent implements OnInit {
  @ViewChild('handleInput') handleInput;
  @ViewChild('handleButton') handleButton;
  @ViewChild('handleButtonIcon') handleButtonIcon;
  @ViewChild('handleErrorBlock') handleErrorBlock;

  handleProcessRunning = false;
  currentUser: Observable<User>

  constructor(private fb: FormBuilder,
    public auth: AuthService,
    private renderer: Renderer2,
    private afs: AngularFirestore,
    private http: HttpClient,
    private dataService: DataService)
    {
    this.currentUser = this.auth.user
  }

  ngOnInit() {
    console.log("init")
  }

  changeRSIHanlde() {
    if (this.handleProcessRunning == true) {
      //console.log("already running...")
      return
    }
    this.handleProcessRunning = true
    const newHandle = this.handleInput.nativeElement.value.toLowerCase()
    console.log("new handle : " + newHandle)
    this.renderer.setAttribute(this.handleButtonIcon.nativeElement, 'class', 'fa fa-circle-o-notch fa-spin fast-spin')
    this.renderer.setAttribute(this.handleButton.nativeElement, 'class', 'btn btn-warning')
    this.renderer.setProperty(this.handleButtonIcon.nativeElement, 'disabled', null)
    

    this.delay(1000).then(any => {
      this.afs.collection('users', ref => ref.where('handle_lower', '==', newHandle)).valueChanges().pipe(
      take(1)
      )
      .subscribe(res => {
        if (res.length > 0) {
          this.showError("Ce handle RSI a déjà été utilisé")
          return
        }
        const checkTokenUrl = `https://us-central1-prisma-manager-3b69b.cloudfunctions.net/check_handle?handle=${newHandle}&user_id=${this.auth.userID.value}`
        this.http.get(checkTokenUrl).subscribe(resp => {
          if(resp!= null && resp["success"] == true) {
            this.showSuccess("Votre handle RSI a été changé avec succès !")
          }
          else {
            this.showError("Impossible de verifier votre code d'authentification.")
          }
        })
      })
    });
  }

  showError(message: string) {
    this.renderer.setProperty(this.handleErrorBlock.nativeElement, 'innerHTML', message)
    this.renderer.setAttribute(this.handleErrorBlock.nativeElement, 'class', 'text-danger')
    this.renderer.setAttribute(this.handleButton.nativeElement, 'class', 'btn btn-danger')
    this.renderer.setAttribute(this.handleButtonIcon.nativeElement, 'class', 'fa fa-times')
    this.delay(2000).then(any => {
      this.renderer.setAttribute(this.handleButton.nativeElement, 'class', 'btn btn-primary')
      this.renderer.setAttribute(this.handleButtonIcon.nativeElement, 'class', 'fa fa-arrow-right')
      this.handleProcessRunning = false
    });
  }

  showSuccess(message: string) {
    this.handleInput.nativeElement.value = null
    this.renderer.setProperty(this.handleErrorBlock.nativeElement, 'innerHTML', message)
    this.renderer.setAttribute(this.handleErrorBlock.nativeElement, 'class', 'text-success')
    this.renderer.setAttribute(this.handleButton.nativeElement, 'class', 'btn btn-success')
    this.renderer.setAttribute(this.handleButtonIcon.nativeElement, 'class', 'fa fa-check')
    this.delay(2000).then(any => {
      this.renderer.setProperty(this.handleErrorBlock.nativeElement, 'innerHTML', '')
      this.renderer.setAttribute(this.handleButton.nativeElement, 'class', 'btn btn-primary')
      this.renderer.setAttribute(this.handleButtonIcon.nativeElement, 'class', 'fa fa-arrow-right')
      this.handleProcessRunning = false
    });
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms));
  }

}
