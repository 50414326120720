import { Component, OnInit, Input } from '@angular/core';
import { OwnedShip, Ship } from 'src/app/models/ship';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { DataService } from 'src/app/lib/data.service';

@Component({
  selector: 'app-ship-history-cell',
  templateUrl: './ship-history-cell.component.html',
  styleUrls: ['./ship-history-cell.component.css']
})
export class ShipHistoryCellComponent implements OnInit {

  @Input()
  public owning: OwnedShip;

  user: Observable<User>
  ship: Observable<Ship>

  constructor(private ds: DataService) {
    
  }

  ngOnInit() {
    this.user = this.ds.fetchObject(this.owning.userID, User)
    this.ship = this.ds.fetchObject(this.owning.shipID, Ship)
  }

}
