import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipMiniComponent } from './cells/ship-mini/ship-mini.component';
import { AppRoutingModule } from '../app-routing.module';
import { FleetPickerComponent } from './fleet-picker/fleet-picker.component';
import { ShipOwnedComponent } from './cells/ship-owned/ship-owned.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContentLoaderComponent } from '../layout/content-loader/content-loader.component';
import { HandleInputComponent } from './handle-input/handle-input.component';
import { DynamicButtonComponent } from './dynamic-button/dynamic-button.component';
import { UserListComponent } from './lists/user-list/user-list.component';
import { UserCellComponent } from './cells/user-cell/user-cell.component';
import { RouterModule } from '@angular/router';
import { ShipHistoryCellComponent } from './cells/ship-history-cell/ship-history-cell.component';
import { MissionCellComponent } from './cells/mission-cell/mission-cell.component';
import { MissionMiniCellComponent } from './cells/mission-mini-cell/mission-mini-cell.component';
import { MissionTypeBadgeComponent } from './micro/mission-type-badge/mission-type-badge.component';
import { SectionBadgeComponent } from './micro/section-badge/section-badge.component';
import {TranslateModule} from '@ngx-translate/core';
import { PageLoaderComponent } from '../layout/page-loader/page-loader.component';

@NgModule({
  declarations: [
    ShipMiniComponent,
    FleetPickerComponent,
    ShipOwnedComponent,
    ContentLoaderComponent,
    HandleInputComponent,
    DynamicButtonComponent,
    UserListComponent,
    UserCellComponent,
    ShipHistoryCellComponent,
    MissionCellComponent,
    MissionMiniCellComponent,
    MissionTypeBadgeComponent,
    SectionBadgeComponent,
    PageLoaderComponent
  ],
  exports: [
    ShipMiniComponent,
    FleetPickerComponent,
    ShipOwnedComponent,
    ContentLoaderComponent,
    HandleInputComponent,
    DynamicButtonComponent,
    UserListComponent,
    UserCellComponent,
    ShipHistoryCellComponent,
    MissionCellComponent,
    MissionMiniCellComponent,
    MissionTypeBadgeComponent,
    SectionBadgeComponent,
    PageLoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule
  ]
})
export class SharedModule { }
