import { Component, OnInit,Input } from '@angular/core';
import { MissionType } from 'src/app/models/mission';

@Component({
  selector: '[mission-badge]',
  templateUrl: './mission-type-badge.component.html',
  styleUrls: ['./mission-type-badge.component.css']
})
export class MissionTypeBadgeComponent implements OnInit {

  @Input()
  public missionType: MissionType;

  constructor() { }

  ngOnInit() {
  }

}
